"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.$toJSON = exports.$readJSON = void 0;

var _index = require("../index");

const $readJSON = async (file, options) => {
  const {
    method,
    headers
  } = {
    method: "GET",
    headers: {},
    ...options
  };

  if (typeof file === "string" && file.startsWith("http")) {
    return new Promise(resolve => {
      fetch(file, {
        method,
        headers
      }).then(response => {
        if (response.status !== 200) {
          throw new Error(`Failed to load ${file}`);
        }

        response.json().then(json => {
          resolve(new _index.DataFrame(json));
        });
      }).catch(err => {
        throw new Error(err);
      });
    });
  } else if (file instanceof File) {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.readAsText(file);

      reader.onload = event => {
        const jsonObj = JSON.parse(event.target.result);
        resolve(new _index.DataFrame(jsonObj));
      };
    });
  } else {
    throw new Error("ParamError: File not supported. file must be a url or an input File object");
  }
};

exports.$readJSON = $readJSON;

const $toJSON = (df, options) => {
  let {
    fileName,
    format,
    download
  } = {
    fileName: "output.json",
    download: true,
    format: "column",
    ...options
  };

  if (df.$isSeries) {
    const obj = {};
    obj[df.columns[0]] = df.values;

    if (download) {
      if (!fileName.endsWith(".json")) {
        fileName = fileName + ".json";
      }

      $downloadFileInBrowser(obj, fileName);
    } else {
      return obj;
    }
  } else {
    if (format === "row") {
      const obj = {};

      for (let i = 0; i < df.columns.length; i++) {
        obj[df.columns[i]] = df.column(df.columns[i]).values;
      }

      if (download) {
        if (!fileName.endsWith(".json")) {
          fileName = fileName + ".json";
        }

        $downloadFileInBrowser(obj, fileName);
      } else {
        return obj;
      }
    } else {
      const values = df.values;
      const header = df.columns;
      const jsonArr = [];
      values.forEach(val => {
        const obj = {};
        header.forEach((h, i) => {
          obj[h] = val[i];
        });
        jsonArr.push(obj);
      });

      if (download) {
        if (!fileName.endsWith(".json")) {
          fileName = fileName + ".json";
        }

        $downloadFileInBrowser(jsonArr, fileName);
      } else {
        return jsonArr;
      }
    }
  }
};

exports.$toJSON = $toJSON;

const $downloadFileInBrowser = (content, fileName) => {
  var hiddenElement = document.createElement('a');
  hiddenElement.href = 'data:text/json;charset=utf-8,' + encodeURI(JSON.stringify(content));
  hiddenElement.target = '_blank';
  hiddenElement.download = fileName;
  hiddenElement.click();
};